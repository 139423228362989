export const AUTH_TOKEN = "monthly-auth-token";
export const AUTH_TOKEN_USER = "monthly-auth-token-user";

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
